import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { MAX_DISPLAYED_MESSAGES_COUNT } from "src/constants";
import { chatSelectors } from "state/selectors";

const baseTemplate = "%s";

const BaseTitle = () => {
  const unreadMessagesCount = useSelector(chatSelectors.getTotalUnreadCount);

  return (
    <Helmet
      defaultTitle="Tango Live Streaming Platform | 1x1 Video Calls with Strangers"
      titleTemplate={
        unreadMessagesCount > 0
          ? `(${
              unreadMessagesCount > MAX_DISPLAYED_MESSAGES_COUNT
                ? `${MAX_DISPLAYED_MESSAGES_COUNT}+`
                : unreadMessagesCount
            }) ${baseTemplate}`
          : baseTemplate
      }
    />
  );
};

export default memo(BaseTitle);
