import React, { ComponentType, Ref, SVGProps } from "react";
import { MessageDescriptor } from "react-intl";
import { Link } from "react-router-dom";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "src/enums";
import { HTMLDivProps } from "src/types/common";
import isNonLinkProps, {
  LinkPropsWithInnerRef,
} from "src/utils/isNonLinkProps";
import Badge from "ui/common/Badge";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { renderTextOrFormattedMessage } from "utils/renderTextOrFormattedMessage";
import styles from "./DropdownListItem.scss";

interface BaseListItemProps {
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  external?: boolean;
  isIconBadgeVisible?: boolean;
  messageClassName?: string;
  titleMessage?: MessageDescriptor | string;
}

interface DivListItemProps extends HTMLDivProps, BaseListItemProps {
  innerRef?: Ref<HTMLDivElement>;
  to?: undefined;
}

interface LinkListItemProps extends LinkPropsWithInnerRef, BaseListItemProps {}

const DropdownListItem: React.FC<DivListItemProps | LinkListItemProps> = (
  props
) => {
  const breakpoint = useBreakpoint();
  const {
    className,
    Icon,
    titleMessage,
    children,
    external,
    messageClassName,
    isIconBadgeVisible,
    ...rest
  } = props;

  const contentTitle = renderTextOrFormattedMessage(titleMessage);

  const dataTestId = `dropdown-list-item-${typeof titleMessage === "string" ? titleMessage : titleMessage?.id}`;

  const content = (
    <>
      {Icon && (
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} />
          {isIconBadgeVisible && <Badge className={styles.badge} />}
        </div>
      )}
      <Typography
        type={
          breakpoint === Breakpoints.DESKTOP
            ? TYPOGRAPHY_TYPE.PARAGRAPH2
            : TYPOGRAPHY_TYPE.PARAGRAPH1
        }
        className={classnames(styles.message, messageClassName)}
        as="div"
      >
        {contentTitle}
        {children}
      </Typography>
    </>
  );

  const classes = classnames(styles.root, className);

  if (isNonLinkProps(rest)) {
    const { innerRef: divInnerRef, ...divOtherProps } = rest;

    return (
      <div
        className={classes}
        onTouchStart={emptyFunction}
        tabIndex={0}
        role="button"
        ref={divInnerRef}
        {...divOtherProps}
      >
        {content}
      </div>
    );
  }

  const { innerRef: linkInnerRef, to, ...linkOtherProps } = rest;

  return external && typeof to === "string" ? (
    <a
      className={classes}
      onTouchStart={emptyFunction}
      href={to}
      target="_blank"
      rel="noreferrer noopener"
      ref={linkInnerRef}
      data-testid={dataTestId}
      {...linkOtherProps}
    >
      {content}
    </a>
  ) : (
    <Link
      className={classes}
      onTouchStart={emptyFunction}
      to={to}
      ref={linkInnerRef}
      data-testid={dataTestId}
      {...linkOtherProps}
    >
      {content}
    </Link>
  );
};

export default DropdownListItem;
