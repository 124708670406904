import React, {
  useContext,
  useRef,
  useLayoutEffect,
  HTMLAttributes,
} from "react";
import EmojiContext from "./EmojiContext";
import { Nullable } from "src/types/common";

const EmojiSpan: React.FC<HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...rest
}) => {
  const { shouldConvertEmojis, convertEmojis } = useContext(EmojiContext);
  const viewRef = useRef<Nullable<HTMLSpanElement>>(null);
  useLayoutEffect(() => {
    if (shouldConvertEmojis && viewRef.current) {
      convertEmojis(viewRef.current);
    }
  }, [shouldConvertEmojis, convertEmojis, children]);
  return (
    <span {...rest} ref={viewRef}>
      {children}
    </span>
  );
};

export default EmojiSpan;
