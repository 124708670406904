import { defineMessages } from "react-intl";

export const menuMessages = defineMessages({
  startBroadcast: {
    id: "start.broadcast",
    defaultMessage: "Start Broadcast",
  },
  games: {
    id: "menu.unauthorised.users.games",
    defaultMessage: "Games",
  },
  language: {
    id: "settings.language",
    defaultMessage: "Language",
  },
  joinTangoCommunity: {
    id: "join.tango.community",
    defaultMessage: "Join Tango Community",
  },
  login: {
    id: "signin",
    defaultMessage: "Sign in",
  },
});
