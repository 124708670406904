import React, { memo } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { Helmet } from "react-helmet";

const FormattedMessageTitle: React.FC<MessageDescriptor> = (props) => {
  const intl = useIntl();
  return <Helmet title={intl.formatMessage(props)} />;
};

export default memo(FormattedMessageTitle);
