import React, { memo } from "react";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import useFilterMenuLinks from "ui/hooks/useFilterMenuLinks";
import { legalLinks } from "ui/newUi/commonLinks";

const LegalLinks: React.FC = () => {
  const links = useFilterMenuLinks(legalLinks);

  return (
    <>
      {links.map(({ link, label, external }) => (
        <DropdownListItem
          key={link}
          titleMessage={label}
          external={external}
          to={link}
        />
      ))}
    </>
  );
};

export default memo(LegalLinks);
