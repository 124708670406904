import React, { memo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { GetTheAppMenuItem } from "src/features/getTheApp/exports/components";
import {
  SocialGamesAnalyticEventName,
  SocialGamesScreenName,
  SocialGamesTargetType,
} from "src/features/socialGames/common/enums";
import useSocialGamesMenuItemEnabled from "src/features/socialGames/hooks/useSocialGamesMenuItemEnabled";
import { ComponentWithClassName, VoidCallback } from "src/types/common";
import UnauthorisedJoinTangoBanner from "ui/common/banner/UnauthorisedJoinTangoBanner";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Divider from "ui/common/divider/Divider";
import CustomerSupportItem from "ui/common/dropdownMenu/menuList/components/CustomerSupportItem";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import ExploreNavigationDarkModeMenuItem from "ui/common/dropdownMenu/menuList/components/ExploreNavigationDarkModeMenuItem";
import LegalLinks from "ui/common/dropdownMenu/menuList/components/LegalLinks";
import Login from "ui/common/dropdownMenu/menuList/components/Login";
import LanguageMenuList from "ui/common/dropdownMenu/menuList/components/languageSwitcher/LanguageMenuList";
import { menuMessages } from "ui/common/dropdownMenu/menuList/messages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import useUiAction from "ui/hooks/useUiAction";
import { linkToSocialGamesBase } from "ui/navigation/links";
import { ReactComponent as BackIcon } from "img/new-ui/burger_menu/ic-burger-mune-back.svg";
import { ReactComponent as ChevronRightIcon } from "img/user_menu_icons/chevron-right.svg";
import { ReactComponent as LanguageIcon } from "img/user_menu_icons/language.svg";
import { ReactComponent as SocialGamesIcon } from "img/user_menu_icons/social-games.svg";
import styles from "ui/common/dropdownMenu/menuList/MenuList.scss";

interface UnauthorisedMenuListProps {
  breakpoint: Breakpoints;
  closeMenu: VoidCallback;
}

const UnauthorisedMenuList: ComponentWithClassName<
  UnauthorisedMenuListProps
> = ({ closeMenu, className, breakpoint }) => {
  const [isLanguageActive, setIsLanguageActive] = useState(false);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const intercept = useExclusiveClickHandler();
  const handleClose = useExclusiveClickHandler(closeMenu);
  const lobbyMatch = useRouteMatch(linkToSocialGamesBase);
  const isSocialGamesMenuItemEnabled = useSocialGamesMenuItemEnabled();
  const shouldShowSocialGames =
    !lobbyMatch?.isExact && isSocialGamesMenuItemEnabled;
  const openLanguageMenu = useExclusiveClickHandler(() =>
    setIsLanguageActive(true)
  );

  const reportGoSocialGamesUiAction = useUiAction({
    [SocialGamesAnalyticEventName.TARGET]:
      SocialGamesTargetType.SOCIAL_GAMES_ENTER,
    additionalParams: {
      [SocialGamesAnalyticEventName.SCREEN_NAME]: SocialGamesScreenName.PROFILE,
    },
  });

  if (isLanguageActive) {
    return (
      <LanguageMenuList
        breakpoint={breakpoint}
        closeMenu={closeMenu}
        setIsLanguageActive={setIsLanguageActive}
      />
    );
  }

  return (
    <Typography
      type={isDesktop ? TYPOGRAPHY_TYPE.PARAGRAPH2 : TYPOGRAPHY_TYPE.PARAGRAPH1}
      onClick={isDesktop ? closeMenu : undefined}
      className={classnames(
        styles.root,
        styles[breakpoint],
        styles.right,
        className
      )}
      as="div"
      data-testid="explore-unathorized-menu"
    >
      <div className={styles.container}>
        {!isDesktop && (
          <div className={styles.header} onClick={intercept}>
            <Button
              className={styles.back}
              size={ButtonSize.MEDIUM_32}
              variant={ButtonVariant.ICON_ONLY}
              onClick={handleClose}
              dataTestId="unathorized-menu-back"
            >
              <BackIcon />
            </Button>
          </div>
        )}
        {!isDesktop && (
          <>
            <UnauthorisedJoinTangoBanner className={styles.banner} />
            <Divider />
            <GetTheAppMenuItem />
            <Divider />
          </>
        )}
        {shouldShowSocialGames && (
          <DropdownListItem
            to={linkToSocialGamesBase}
            Icon={SocialGamesIcon}
            data-testid="socialGames"
            onClick={reportGoSocialGamesUiAction}
            titleMessage={menuMessages.games}
          />
        )}
        {!isDesktop && <Divider />}
        <ExploreNavigationDarkModeMenuItem />
        <DropdownListItem
          onClick={openLanguageMenu}
          Icon={LanguageIcon}
          data-testid="language"
          titleMessage={menuMessages.language}
        >
          <div className={styles.forward}>
            <ChevronRightIcon />
          </div>
        </DropdownListItem>
        <CustomerSupportItem withDivider />
        {isDesktop && (
          <>
            <GetTheAppMenuItem />
            <Divider />
            <Login onClick={closeMenu} />
          </>
        )}
        {!isDesktop && <LegalLinks />}
      </div>
    </Typography>
  );
};

export default memo(UnauthorisedMenuList);
