import React, { memo, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import { percentageToString } from "src/utils/numberToPercentage";
import ShopOfferShape from "state/shapes/ShopOfferShape";
import { specialShopActionCreators } from "state/tree/shop";
import { FormattedNumber } from "ui/common/Formatted";
import PriceWithDiscount from "ui/common/PriceWithDiscount";
import PromoTimer from "ui/common/promoTimer/PromoTimer";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { ReactComponent as Coin } from "img/ic_coin.svg";
import styles from "./PromoOffer.scss";

const PromoOffer = ({
  isCashier,
  offer,
  onClick,
  disabled,
  isMobile,
  className,
}) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => onClick(offer), [offer, onClick]);

  const handleExpire = useCallback(() => {
    dispatch(
      specialShopActionCreators.removePromoOffer({
        flag: offer.promoConfig.flags,
        offerSku: offer.promoConfig.offer_sku,
      })
    );
  }, [offer]);

  useEffect(() => {
    if (!offer.promoConfig.isViewed) {
      dispatch(
        specialShopActionCreators.promoOfferViewed({
          flag: offer.promoConfig.flags,
          offerSku: offer.promoConfig.offer_sku,
        })
      );
    }
  }, [offer.promoConfig.isViewed]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        className,
        disabled && styles.disabled,
        isMobile && styles.mobile
      )}
    >
      <div
        className={classnames(styles.root, {
          [styles.cashier]: isCashier,
        })}
        onClick={handleClick}
        role="button"
        onTouchStart={emptyFunction}
      >
        <div className={styles.ribbon}>
          <div className={styles.sale}>
            <span>{percentageToString(offer.discountPercent)}</span>
            <span className={styles.off}>
              <FormattedMessage
                id="cashier.promo.offer.sale"
                defaultMessage="off"
              />
            </span>
          </div>
        </div>
        <PromoTimer
          className={styles.timer}
          ts={offer.promoConfig.ts}
          duration={offer.promoConfig.duration}
          freeze={offer.promoConfig.freeze}
          onEnd={handleExpire}
        />
        <div className={styles.body}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${offer.image})`,
            }}
          />
          <div className={classnames(styles.info, isMobile && styles.mobile)}>
            <div className={styles.credits}>
              <Coin width={30} height={30} />
              <span>
                <FormattedNumber value={offer.credits || 0} />
              </span>
            </div>
            <PriceWithDiscount
              offer={offer}
              typographyPriceType={TYPOGRAPHY_TYPE.HEADLINE5}
              typographyOriginalPriceType={TYPOGRAPHY_TYPE.HEADLINE6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PromoOffer.propTypes = {
  className: PropTypes.string,
  isCashier: PropTypes.bool,
  offer: ShopOfferShape.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default memo(PromoOffer);
